import * as React from 'react'
import Layout from '../components/layout'

const SupportPage = () => {
  return (
    <Layout pageTitle="Support">
      <div class="top-bar">
        <h1 class="left-side">How To Support</h1>
      </div>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Ultrices mi tempus imperdiet nulla malesuada pellentesque. Turpis nunc eget lorem dolor sed viverra. Id eu nisl nunc mi ipsum faucibus. Turpis cursus in hac habitasse. Etiam dignissim diam quis enim. Aliquam sem et tortor consequat. Eget mi proin sed libero enim sed faucibus turpis. Cursus euismod quis viverra nibh cras. Nunc sed id semper risus in. Semper risus in hendrerit gravida rutrum. Egestas sed sed risus pretium quam vulputate. Id eu nisl nunc mi ipsum faucibus.</p>

      <p>Libero enim sed faucibus turpis in eu mi. Aliquet nibh praesent tristique magna sit amet. Mattis vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum. Bibendum enim facilisis gravida neque convallis a cras semper auctor. Aenean vel elit scelerisque mauris pellentesque. Semper feugiat nibh sed pulvinar proin. Donec enim diam vulputate ut pharetra. Ornare arcu dui vivamus arcu. Placerat duis ultricies lacus sed turpis tincidunt id. Quam lacus suspendisse faucibus interdum posuere lorem.</p>

      <p>Id interdum velit laoreet id donec. Vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare. Parturient montes nascetur ridiculus mus mauris. Amet est placerat in egestas erat imperdiet. Diam quam nulla porttitor massa id neque aliquam vestibulum. Lorem ipsum dolor sit amet consectetur. Justo donec enim diam vulputate ut pharetra sit amet aliquam. Fermentum dui faucibus in ornare. Id cursus metus aliquam eleifend mi in nulla. Nibh sed pulvinar proin gravida. Sit amet est placerat in. Nec feugiat nisl pretium fusce id.</p>
      
      <p>Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Sem fringilla ut morbi tincidunt augue interdum. Velit egestas dui id ornare arcu odio ut. Enim nulla aliquet porttitor lacus luctus accumsan tortor. Consectetur a erat nam at lectus urna duis convallis convallis. Suscipit adipiscing bibendum est ultricies integer. Neque vitae tempus quam pellentesque nec. Ultrices tincidunt arcu non sodales. Magna fringilla urna porttitor rhoncus dolor purus non enim. Tortor vitae purus faucibus ornare suspendisse sed nisi. Praesent tristique magna sit amet purus gravida quis blandit turpis. Iaculis eu non diam phasellus vestibulum lorem sed risus. Magna fermentum iaculis eu non. Erat imperdiet sed euismod nisi porta lorem mollis aliquam.</p>
      
      <p>Aliquam purus sit amet luctus venenatis. Dignissim convallis aenean et tortor at risus viverra adipiscing. Ipsum faucibus vitae aliquet nec. Quam nulla porttitor massa id neque aliquam vestibulum morbi. Amet purus gravida quis blandit turpis cursus. Cursus risus at ultrices mi tempus imperdiet nulla malesuada. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor. Parturient montes nascetur ridiculus mus. Maecenas accumsan lacus vel facilisis. Nibh sed pulvinar proin gravida. Sit amet purus gravida quis. Massa eget egestas purus viverra accumsan in nisl nisi. Accumsan lacus vel facilisis volutpat est. In ante metus dictum at. Facilisis magna etiam tempor orci eu lobortis elementum nibh tellus. Ac ut consequat semper viverra nam libero. Amet aliquam id diam maecenas ultricies mi eget. Scelerisque eu ultrices vitae auctor eu augue ut.</p>
    </Layout>)
}

export default SupportPage
